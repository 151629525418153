const PageNotFound = () => {
   return (
      <div className="flex flex-col items-center justify-center my-5">
         <p className="text-6xl font-bold">404</p>
         <p>Oops! Page not found</p>
      </div>
   );
};

export { PageNotFound };
