import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LiveEventQuestionsWrapper } from "./page-wrapper";
import { LiveEventQuestionForm } from "./question-form";

export const STATUS = {
   LOADING: "LOADING",
   READY: "READY",
   ERROR: "ERROR",
   NOT_FOUND: "NOT_FOUND",
   SUBMITTED: "SUBMITTED",
};

export function LiveEventQuestions() {
   const [status, setStatus] = useState(STATUS.LOADING);

   const params = useParams();
   const eventAlias = params.eventAlias;

   useEffect(() => {
      if (!eventAlias) {
         setStatus(STATUS.NOT_FOUND);
         return;
      }

      async function validateEventAlias() {
         const url = "/v1/live-events/" + eventAlias;
         try {
            const res = await fetch(url);
            if (res.status !== 200) {
               throw new Error("Could not validate event alias");
            }
            setStatus(STATUS.READY);
         } catch (error) {
            setStatus(STATUS.NOT_FOUND);
         }
      }

      validateEventAlias();
   }, [eventAlias]);

   if (status === STATUS.LOADING) {
      return (
         <LiveEventQuestionsWrapper>
            <div className="text-center my-8">
               <Spinner className="mx-auto" />
            </div>
         </LiveEventQuestionsWrapper>
      );
   }
   if (status === STATUS.NOT_FOUND) {
      return (
         <LiveEventQuestionsWrapper>
            <div className="text-center my-8">
               <p className="font-bold text-8xl">404</p>
               <p>We could not find the event you are looking for</p>
            </div>
         </LiveEventQuestionsWrapper>
      );
   }

   if (status === STATUS.SUBMITTED) {
      return (
         <LiveEventQuestionsWrapper>
            <div className="text-center my-8">
               <h1 className="text-4xl font-semibold mb-4">Thank you!</h1>
               <p>Your question has been submitted</p>
            </div>
         </LiveEventQuestionsWrapper>
      );
   }
   if (status === STATUS.READY) {
      return <LiveEventQuestionForm eventAlias={eventAlias} setStatus={setStatus} />;
   }
}
