import React from "react";
import PageHero from "../../components/PageHero";
import "./index.scss";

function Leader({ leader, variant = "other-leaders" }) {
   const { name, role, picture } = leader;
   return (
      <div className="mb-4">
         <img
            src={`https://assets.theharvestword.org/images/leadership/${picture}`}
            alt={`${name}, Harvest Word Assembly, ${role}`}
            className={`rounded-full mb-4 !border-[3px] ${
               variant === "other-leaders" ? "border-[#555]" : "border-[#333]"
            }`}
         />
         <div className="flex flex-col">
            <div className="font-bold">{name}</div>
            <small className="georgia-font italic">{role}</small>
         </div>
      </div>
   );
}

const seniorPastor = {
   name: "Pastor Gbenga Shafe",
   role: "Senior Pastor",
   picture: "pastor_gbenga_shafe.png",
};

const otherLeaders = [
   { name: "Pastor Olawumi Shafe", role: "Co-Senior Pastor", picture: "pastor_olawumi_shafe.png" },
   {
      name: "Pastor David Adeyemi",
      role: "Resident Pastor (Lekki Worship Centre)",
      // role: "Resident Pastor (Maryland Worship Centre)",
      picture: "pastor_david_adeyemi.png",
   },
   // These entries were removed based on the senior pastor's request
   // {
   //   name: "Pastor Ighotome Mukoro",
   //   role: "Resident Pastor (Lekki Worship Centre)",
   //   picture: "pastor_ighotome_mukoro.png",
   // },
   // { name: "Pastor Kehinde Adeboboye", role: "", picture: "pastor_kehinde_adeboboye.png" },
   {
      name: "Pastor Ose Haastrup",
      role: "Resident Pastor (Gbagada Worship Centre)",
      picture: "pastor_abosede_haastrup.png",
   },
   { name: "Pastor Bara Michael", role: "", picture: "pastor_baratua_michael.png" },
];

function AboutUs() {
   return (
      <>
         <PageHero heroBg="aboutus_bg.jpg">About Us</PageHero>
         <section className="wrapper text-center">
            <div className="mx-5 mb-12">
               <header className="section__header">
                  <h1>The Church</h1>
               </header>
               <p>
                  The Church was born on the 1st of March, 2015 and the inaugural service was held at Light House Event
                  Center, Association Road, Ilupeju Lagos.
               </p>
               <p>
                  The Harvest Word Assembly is a local church committed to providing a safe haven for God&acute;s
                  children and also for unsaved people in the community so that they can be taught the ways of our GOD
                  and of the Kingdom in an atmosphere filled with God's love and presence.
               </p>
               <p>
                  We are a place of refuge for broken and tired people who come to the lord for covering; as such we
                  serve with a shepherd's heart. Our ultimate goal is restore the broken until they are established in
                  God's gifts and calling for their lives.
               </p>
            </div>
         </section>
         <section className="section section--blue">
            <header className="section__header">Our Representation</header>
            <h2 className="font-semibold">Vision</h2>
            <p className="italic georgia-font">
               To build a city of refuge where all can find and dwell in GOD&acute;s love
            </p>
            <br />
            <h2 className="font-semibold">Mission</h2>
            <div>
               <ul className="italic georgia-font ps-0">
                  <li>Cultivating an atmosphere filled with the Love and Presence of the Holy Spirit</li>
                  <li>Developing and deploying Kingdom Leadership</li>
                  <li>Deploying the five fold ministry gifts and other Scripture based Spiritual gifts</li>
                  <li>Starting local churches around the world</li>
               </ul>
            </div>
            <br />
            <h2 className="font-semibold">Core Values</h2>
            <div>
               <ul className="italic georgia-font ps-0">
                  <li>God's manifest presence</li>
                  <li>Accurate delivery of God's word</li>
                  <li>Excellence and order</li>
                  <li>Love and care</li>
               </ul>
            </div>
         </section>
         <section className="wrapper text-center">
            <div className="mb-12">
               <header className="section__header">Our Leadership</header>
               <div className="main-leader mb-8">
                  <Leader leader={seniorPastor} />
               </div>

               <div className="other-leaders | flex flex-wrap justify-center gap-8">
                  {otherLeaders.map((leader, index) => (
                     <Leader key={index} leader={leader} variant="other-leaders" />
                  ))}
               </div>
            </div>
         </section>
      </>
   );
}

export default AboutUs;
