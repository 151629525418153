import { Outlet } from "react-router-dom";
import { PageFooter } from "./page-footer";
import { PageHeader } from "./page-header";

const Layout = () => {
   return (
      <>
         <PageHeader />
         <main>
            <Outlet />
         </main>
         <PageFooter />
      </>
   );
};

export { Layout };
