import { isAlphanumeric, isEmpty, isLength } from "validator";

export function validateText(
   text,
   { optional = false, entity = "", minCharacterLength = 6, maxCharacterLength = 300 }
) {
   const error = {};

   if (!optional || text) {
      if (isEmpty(text)) {
         error[entity.toLowerCase()] = entity + " is required";
      } else if (!isLength(text, { min: minCharacterLength, max: maxCharacterLength })) {
         error[
            entity.toLowerCase()
         ] = `${entity} is required to be between ${minCharacterLength} and ${maxCharacterLength} characters long`;
      } else if (!isAlphanumeric(text, ["en-GB"], { ignore: " -:.;,'?\n!" })) {
         error[entity.toLowerCase()] = `Only alphanumeric characters are allowed for ${entity.toLowerCase()}`;
      }
   }

   return error;
}
