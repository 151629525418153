import { isAlpha, isLength } from "validator";

export function validateName(name = "", { optional = false, entity = "" }) {
  const error = {};

  const MIN_CHARACTER_LENGTH = 2,
    MAX_CHARACTER_LENGTH = 100;

  if (!optional || name) {
    if (!isLength(name + "", { min: MIN_CHARACTER_LENGTH, max: MAX_CHARACTER_LENGTH })) {
      error[
        entity.toLowerCase()
      ] = `${entity} is required to be between ${MIN_CHARACTER_LENGTH} and ${MAX_CHARACTER_LENGTH} characters long`;
    } else if (!isAlpha(name, ["en-GB"], { ignore: " -" })) {
      error[
        entity.toLowerCase()
      ] = `Only alphabets, space and hyphen are allowed for ${entity.toLowerCase()}`;
    }
  }
  return error;
}
