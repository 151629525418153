import React from "react";
import PageHero from "../../components/PageHero";
import { SectionHeader, SectionWrapper } from "../../components/ui/wrapper";

function Give() {
   return (
      <>
         <PageHero heroBg="giving_bg.jpg">Giving</PageHero>
         <div className="mb-12">
            <SectionWrapper className="text-center mb-12">
               <SectionHeader>BANK TRANSFER</SectionHeader>
               <p className="mb-4">Give by direct transfer to the bank account below:</p>
               <div className="mb-5">
                  <p className="italic georgia-font text-2xl">0075493511</p>
                  <p className="text-xl md:text-2xl italic georgia-font font-semibold">Access Bank</p>
                  <p className="font-semibold italic georgia-font">Harvest Word Streams Intl Center</p>
               </div>
               <div className="mb-5">
                  <p className="italic georgia-font text-2xl">0087203221 (USD)</p>
                  <p className="text-xl md:text-2xl italic georgia-font font-semibold">Access Bank</p>
                  <p className="font-semibold italic georgia-font">Harvest Word Streams Intl Center</p>
               </div>
               <div className="mb-5">
                  <p className="italic georgia-font text-2xl">1720109606 (GBP) / 1513703349</p>
                  <p className="text-xl md:text-2xl italic georgia-font font-semibold">Access Bank</p>
                  <p className="font-semibold italic georgia-font">Harvest Word Streams (Partner)</p>
               </div>
            </SectionWrapper>

            <SectionWrapper className="text-center" variant="moderate">
               <SectionHeader>
                  <header className="">Thank You For Giving!</header>
               </SectionHeader>
               <p>
                  When you give to Harvest Word Assembly, you are not just honouring God, you are partnering with us to
                  reach lost souls and welcome them home to a life in Christ! By giving to the church, we are able to
                  impact and reach out to more people.
               </p>
            </SectionWrapper>
         </div>
      </>
   );
}

export default Give;
