import React from "react";
import PageHero from "../../components/PageHero";

const cellLocations = [
   {
      name: "Gbagada Cell",
      address: "23, Babalola street, Gbagada, Lagos",
      contact: "08097656494",
   },
   {
      name: "Surulere Cell",
      address: "8, Ajigbeda Street, Off Moshalashi Bus Stop, Mushin",
      contact: "09025414673",
   },
   {
      name: "Magodo Cell",
      address: "4, Ala close, off Irepodun street, Shangisha, Magodo, Lagos.",
      contact: "08131980070",
   },
   {
      name: "Agbole Cell",
      address: "3, Kolawole Abdul street, Alagbole, Ogun State.",
      contact: "07036995431",
   },
   {
      name: "Ogudu",
      address: "22, Association close, Ogudu roundabout, Ogudu, Lagos.",
      contact: "08106721603",
   },
   {
      name: "Ajao Estate Cell",
      address: "1A and 1B Omolabake Adeoti Street. Ajao Estate, Lagos.",
      contact: "08063645654",
   },
   {
      name: "Ketu Cell",
      address: "20, Abiola street, Alapere, Ketu, Lagos.",
      contact: "08106721603",
   },
];

const sortedLocations = cellLocations.sort((a, b) => {
   const propA = a.name,
      propB = b.name;
   if (propA < propB) {
      return -1;
   }
   if (propA > propB) {
      return 1;
   }
   return 0;
});

function CellLocations() {
   return (
      <>
         <PageHero heroBg="cell-locations.jpg">Cell Locations</PageHero>
         <section className="wrapper text-center mb-12">
            <header className="section__header">
               <span className="bi bi-geo-alt text-[#00b3ff]"></span>&nbsp; Cell Fellowship Locations
            </header>
            <div className="locations-container mb-2 grid-m2">
               {sortedLocations.map((cell, index) => (
                  <CellLocation key={index} cell={cell} />
               ))}
            </div>
         </section>
      </>
   );
}

function CellLocation({ cell }) {
   const { name, address, contact } = cell;
   return (
      <div className="cell-location mb-6">
         <div className="text-2xl font-bold text-[#004e76]">{name}</div>
         <div className="text-[#6c757d] georgia-font italic text-sm">
            <span>{address}</span>
            <p>
               <span className="font-semibold">Phone: </span>
               <span>{contact}</span>
            </p>
         </div>
      </div>
   );
}

export default CellLocations;
