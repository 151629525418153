function FlexCenter({ children, className, ...rest }) {
  const defaultClassNames = "flex justify-center items-center";
  className = className ? className + " " + defaultClassNames : defaultClassNames;
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}

export default FlexCenter;
