import { Link } from "react-router-dom";

export function LiveEventQuestionsWrapper({ children }) {
  return (
    <div className="page-wrapper | flex flex-col h-full justify-center items-center bg-primary-400">
      <Link to="/">
        <div className="flex mb-4 md:mb-6 bg-white rounded-full p-1">
          <img
            className="w-[5rem]"
            src="https://assets.theharvestword.org/images/hwa_logo.png"
            alt="Harvest World Assembly logo"
          />
        </div>
      </Link>
      <div className="w-11/12 md:w-8/12 lg:w-5/12 bg-white p-6 md:p-8 rounded-2xl">{children}</div>
    </div>
  );
}
