import Button from "../ui/button/Button";
import "./index.scss";

export function PaginationButton({ icon, className, ...rest }) {
  const defaultClassNames = "border-0 m-1 pagination__btn";
  className = className ? defaultClassNames + " " + className : defaultClassNames;
  return (
    <Button className={className} role="button" tabIndex="0" iconOnly {...rest}>
      <i className={`flex justify-center items-center  ${icon}`}></i>
    </Button>
  );
}

const Pagination = ({ setPage, page, totalPages }) => {
  return (
    <div className="pagination flex justify-center">
      <div className="pagination__container flex items-center p-1">
        <PaginationButton
          icon="bi bi-chevron-double-left"
          disabled={page <= 1}
          onClick={() => setPage(1)}
        />
        <PaginationButton
          icon="bi bi-chevron-left"
          disabled={page <= 1}
          onClick={() => setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1))}
        />
        <select className="mx-2" onChange={(e) => setPage(+e.target.value)} value={page}>
          {[...Array(totalPages)].map((e, key) => (
            <option value={key + 1} key={key}>
              {key + 1}
            </option>
          ))}
        </select>
        <i className="me-2">of {totalPages}</i>
        <PaginationButton
          icon="bi bi-chevron-right"
          disabled={page === totalPages}
          onClick={() => setPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : totalPages))}
        />
        <PaginationButton
          icon="bi bi-chevron-double-right"
          disabled={page === totalPages}
          onClick={() => setPage(totalPages)}
        />
      </div>
    </div>
  );
};

export default Pagination;
