import FlexCenter from "../../components/FlexCenter";
import PageHero from "../../components/PageHero";

function Meeting({ meeting }) {
   const { name, time, icon } = meeting;
   return (
      <div className="meeting | flex flex-col items-center">
         <FlexCenter className="mb-4 rounded-full">
            <span className={`meeting__icon | ${icon} text-6xl`}></span>
         </FlexCenter>
         <p className="meeting__name | mb-3 leading-6">{name}</p>
         <span className="meeting__time | text-white text-sm italic georgia-font">{time}</span>
      </div>
   );
}

const meetings = [
   { name: "Worship Service", time: "Every Sunday | 8AM & 9:50AM", icon: "fa fa-praying-hands" },
   { name: "Bible Study", time: "Every Tuesday | 6PM", icon: "fa fa-bible" },
   { name: "Prayer Meeting", time: "Every Friday | 6PM (Online only)", icon: "fa fa-pray" },
   { name: "Thy Kingdom Come prayers", time: "Every Wed, Thur & Fri | 6:30 AM (Online only)", icon: "fa fa-pray" },
];

function Home() {
   return (
      <>
         <PageHero heroBg="home_bg.jpg" isClear />
         <section className="section section">
            <header className="section__header">Harvest Word Assembly</header>
            Harvest Word Assembly is a prophetic and apostolic ministry established on the scriptures
            <blockquote>
               <q className="italic">
                  Do you not say, &apos;It is still four months until the harvest comes?&apos; Look, I say to you, raise
                  your eyes and look at the fields and see, they are white for harvest
               </q>
               <br />
               <strong>John 4:35 (AMP)</strong>
            </blockquote>
            and
            <br />
            <blockquote>
               <q className="italic">
                  And he gave some apostles and some, prophets and some evangelists and some, pastors and some teachers
                  for the perfecting of the saints, for the work of the ministry, for the edifying of the body of Christ
                  till we all come in the unity of the faith and of the knowledge of the son of GOD unto a perfect man
                  unto the measure of the fullness of Christ
               </q>
               <br />
               <strong>Ephesians 4:11-13 (KJV)</strong>
            </blockquote>
            Growing in the grace of GOD. We seek to labour in the spirit to present men in his image to him blameless
            through the ministry of the word of God. We also share a strong passion for missions support, less
            privileged and handicapped children.
            <br />
            We are open to all pure channels of ministry and you can let us know how we may be a blessing in your area.
         </section>
         <section className="section section--black !py-20">
            <header className="font-semibold text-4xl mb-16">Our Meetings</header>
            <div className="meetings | flex flex-col gap-16 lg:flex-row lg:justify-center max-w-[80%] mx-auto">
               {meetings.map((meeting, index) => (
                  <Meeting key={index} meeting={meeting} />
               ))}
            </div>
         </section>
         <section className="section" id="confession">
            <header className="section__header">Confession</header>
            <div className="georgia-font italic text-md-margin-inline mx-auto lh-sm">
               <div className="mb-12">
                  <p>This is my year of flourishing</p>
                  <p>This is my year of singing</p>
                  <p>And my year of rejoicing</p>
                  <p>I am a tree planted by the rivers of living water And I bring forth fruit in season</p>
               </div>
               <div className="mb-12">
                  <p>I am the righteous in christ</p>
                  <p>And the righteous shall flourish like a palm tree</p>
                  <p>I am planted in the house of the lord</p>
                  <p>Therefore I will flourish in the courts of our God</p>
               </div>
               <div className="mb-12">
                  <p>I will continue to bring forth fruit in my old age</p>
                  <p>I will flourish as a mark that the Lord is upright and faithful</p>
               </div>
               <div className="mb-12">
                  <p>This is my season of flourishing</p>
                  <p>I will prosper in the counsels of the lord Jesus</p>
                  <p>I will abide in his presence and obey his word</p>
                  <p>In this season, I will rise on eagles wings and soar on the strength of his commands</p>
               </div>
               <div className="mb-12">
                  <p>I am the proof of his love</p>
                  <p>That&apos;s why I flourish</p>
                  <p>That&apos;s why I thrive</p>
                  <p>Amen</p>
               </div>
            </div>
         </section>
      </>
   );
}

export default Home;
