import { useRef } from "react";
import { CustomNavLink } from "../components/custom-nav-link";

const PageHeader = () => {
   const navRef = useRef();
   return (
      <>
         <header className="navigation flex">
            <CustomNavLink to="/">
               <div className="brand flex">
                  <div className="brand__logo | flex me-2">
                     <img
                        src="https://assets.theharvestword.org/images/hwa_logo.png"
                        alt="Harvest World Assembly logo"
                        width="100%"
                        height="100%"
                     />
                  </div>
                  <div className="flex flex-col justify-center leading-none">
                     <p className="font-bold text-[1.2rem]">Harvest Word Assembly</p>
                     <small className="italic">...City of Refuge</small>
                  </div>
               </div>
            </CustomNavLink>
            <nav ref={navRef} className="hidden lg:flex lg:justify-end">
               <div className="nav__links">
                  <CustomNavLink to="/">Home</CustomNavLink>
                  <CustomNavLink to="/about">About Us</CustomNavLink>
                  <CustomNavLink to="/sermons">Downloads</CustomNavLink>
                  <CustomNavLink to="/cell-locations">Cell Locations</CustomNavLink>
                  <CustomNavLink to="/connect">Connect</CustomNavLink>
                  <CustomNavLink to="/giving">Give</CustomNavLink>
               </div>
            </nav>
            <button
               className="hamburger | bg-transparent border-0 flex-col justify-center items-center lg:hidden flex"
               onClick={(e) => {
                  navRef.current.classList.toggle("showNav");
               }}>
               <div></div>
               <div></div>
               <div></div>
            </button>
         </header>
      </>
   );
};

export { PageHeader };
