import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageTitle } from "./components/page-title";
import AboutUs from "./pages/about-us";
import CellLocations from "./pages/cell-locations";
import ConnectPage from "./pages/connect";
import Give from "./pages/give";
import Home from "./pages/home/";
import { Layout } from "./pages/layout";
import { LiveEventQuestions } from "./pages/live-event-questions";
import { PageNotFound } from "./pages/page-not-found";
import Sermons from "./pages/sermons/Sermons";

const routeMap = [
   { path: "/", title: "Harvest Word Assembly", element: <Home /> },
   { path: "about", title: "About Us | Harvest Word Assembly", element: <AboutUs /> },
   { path: "sermons", title: "Sermons | Harvest Word Assembly", element: <Sermons /> },
   { path: "download", title: "Sermons | Harvest Word Assembly", element: <Sermons /> },
   { path: "giving", title: "Giving | Harvest Word Assembly", element: <Give /> },
   { path: "cell-locations", title: "Cell Locations | Harvest Word Assembly", element: <CellLocations /> },
   { path: "connect", title: "Connect | Harvest Word Assembly", element: <ConnectPage /> },
];

function App() {
   return (
      <BrowserRouter>
         <Routes>
            <Route element={<Layout />}>
               {routeMap.map((_route, index) => (
                  <Route key={index} path={_route.path} element={_route.element} />
               ))}
            </Route>

            <Route
               path="leq"
               element={
                  <PageTitle title="Live Event Questions | Harvest Word Assembly">
                     <LiveEventQuestions />
                  </PageTitle>
               }
            />
            <Route
               path="leq/:eventAlias"
               element={
                  <PageTitle title="Live Event Questions | Harvest Word Assembly">
                     <LiveEventQuestions />
                  </PageTitle>
               }
            />
            <Route path="*" element={<PageNotFound />} />
         </Routes>
      </BrowserRouter>
   );
}

export default App;
