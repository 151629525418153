import FlexCenter from "./FlexCenter";

function PageHero({ heroBg, children, isClear }) {
  const bgImageStyle = isClear
    ? `url('https://assets.theharvestword.org/images/bgs/${heroBg}')`
    : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://assets.theharvestword.org/images/bgs/${heroBg}')`;
  return (
    <FlexCenter
      className="tag-house | mb-12 text-white"
      style={{
        backgroundImage: bgImageStyle,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
      {children}
    </FlexCenter>
  );
}

export default PageHero;
