import { Form, Formik } from "formik";
import { useState } from "react";
import { STATUS } from ".";
import { ButtonV1 } from "../../components/ui/button/Button";
import { FieldError, FieldLabel } from "../../components/ui/form";
import { validateName } from "../../utils/validators/validateName";
import { validateText } from "../../utils/validators/validateText";
import { LiveEventQuestionsWrapper } from "./page-wrapper";

const ALLOWED_QUESTION_CHAR_LENGTH = 300;

export function LiveEventQuestionForm({ eventAlias, setStatus }) {
  const [submitError, setSubmitError] = useState("");

  function handleValidation({ name, question }) {
    const questionValidationResult = validateText(question, {
      optional: false,
      entity: "Question",
      maxCharacterLength: ALLOWED_QUESTION_CHAR_LENGTH,
    });
    const nameValidationResult = validateName(name, {
      optional: true,
      entity: "Name",
    });

    return { ...nameValidationResult, ...questionValidationResult };
  }

  async function handleSubmit(values) {
    setSubmitError("");
    try {
      const url = `/v1/live-events/${eventAlias}/questions`;
      const options = {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
        method: "POST",
      };
      await (await fetch(url, options)).json();
      setStatus(STATUS.SUBMITTED);
    } catch (error) {
      setSubmitError(error.message);
    }
  }

  return (
    <LiveEventQuestionsWrapper>
      <Formik
        initialValues={{ eventAlias: "", name: "", question: "Testing" }}
        validate={handleValidation}
        onSubmit={handleSubmit}>
        {({ values, isSubmitting, handleChange, errors, setFieldError }) => (
          <Form>
            <div className="grid">
              <header className="text-center my-4">
                <h1 className="text-2xl font-bold">Live Questions</h1>
                <p className="border border-green-600 inline-block px-5 py-1 rounded-2xl bg-green-400 text-white font-medium">
                  #{eventAlias}
                </p>
              </header>

              <div className="form-content | mt-4">
                {submitError && <div className="message message-danger mb-1">{submitError}</div>}

                <div className="grid mb-4">
                  <FieldLabel htmlFor="name">
                    Name{" "}
                    <span className="text-gray-400 italic text-sm font-normal">(optional)</span>
                  </FieldLabel>
                  <input
                    id="name"
                    name="name"
                    className="block w-full px-3 py-[0.45rem] bg-white border-gray-300 border appearance-none rounded focus:border-primary-200 focus:shadow-formControl focus:outline-0"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && <FieldError>{errors.name}</FieldError>}
                </div>
                <div className="grid mb-4">
                  <div className="flex justify-between items-center">
                    <FieldLabel htmlFor="question">
                      Question{" "}
                      <span className="text-red-600 italic text-sm font-normal">(required)</span>
                    </FieldLabel>
                    <span className="text-sm">
                      {values.question.length} / {ALLOWED_QUESTION_CHAR_LENGTH}
                    </span>
                  </div>
                  <textarea
                    id="question"
                    name="question"
                    className="block w-full px-3 py-[0.45rem] bg-white border-gray-300 border appearance-none rounded focus:border-primary-200 focus:shadow-formControl focus:outline-0 resize-none"
                    rows="5"
                    value={values.question}
                    onChange={(e) => {
                      const shouldAppendNewCharacter =
                        e.target.value.length <= ALLOWED_QUESTION_CHAR_LENGTH;
                      if (!shouldAppendNewCharacter) {
                        setFieldError("question", "Maximum length reached");
                      } else {
                        handleChange(e);
                      }
                    }}

                    // required
                  />
                  {errors.question && <FieldError>{errors.question}</FieldError>}
                </div>
                <div className="grid">
                  <ButtonV1 variant="primary">{isSubmitting ? "Submitting..." : "Submit"}</ButtonV1>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </LiveEventQuestionsWrapper>
  );
}
