const PageFooter = () => {
   return (
      <footer className="flex flex-col items-center text-center text-white">
         <header>Reach Us</header>
         <div>
            <span className="bi bi-geo-alt"></span>&nbsp; Jadesola House, Plot 374, Ikorodu Road,
            <br />
            Behind Maryland BRT Bus stop, Maryland, Lagos.
            <br />
            <p>
               <a href="mailto:info@theharvestword.org">
                  <span className="bi bi-envelope"></span>
                  <span>&nbsp; info@theharvestword.org</span>
                  <br />
               </a>
            </p>
            <p>
               <span className="bi bi-telephone"></span>
               <span>&nbsp; +234 810 0565 318, +234 703 483 4338</span>
            </p>
            <div className="flex justify-center">
               <a href="https://www.facebook.com/harvestword/" target="_blank" rel="noopener noreferrer">
                  <div className="social-media-link">
                     <span className="bi bi-facebook"></span>
                  </div>
               </a>
               <a href="https://www.instagram.com/theharvestword/" target="_blank" rel="noopener noreferrer">
                  <div className="social-media-link">
                     <span className="bi bi-instagram"></span>
                  </div>
               </a>
               <a href="https://harvestwordonline.mixlr.com/" target="_blank" rel="noopener noreferrer">
                  <div className="social-media-link">
                     <span>
                        <img src="https://assets.theharvestword.org/images/icons/mixlr.png" alt="Mixlr" />
                     </span>
                  </div>
               </a>
               <a href="https://us04web.zoom.us/j/9386214849" target="_blank" rel="noopener noreferrer">
                  <div className="social-media-link">
                     <span>
                        <img
                           src="https://assets.theharvestword.org/images/icons/zoom-meeting.png"
                           alt="Zoom"
                           className="rounded-full"
                           style={{ filter: "grayscale(100%)" }}
                        />
                     </span>
                  </div>
               </a>
            </div>
         </div>
         <div className="copyright">
            &copy;
            <span className="copyright-year">
               {" "}
               {new Date().getFullYear()} HARVEST WORD ASSEMBLY. ALL RIGHTS RESERVED.
            </span>
         </div>
      </footer>
   );
};

export { PageFooter };
