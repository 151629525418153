import { useNavigate } from "react-router-dom";
import FlexCenter from "./FlexCenter";
import PrimaryButton from "./ui/button/Primary";

function ResourceLoadingError({
  errorMessage = "Oops! Something went wrong, please try again later",
  showButton = true,
}) {
  const navigate = useNavigate();
  return (
    <FlexCenter className="flex-col py-5">
      {errorMessage}
      {showButton && (
        <div className="my-4">
          <PrimaryButton onClick={() => navigate(-1)}>Back</PrimaryButton>
        </div>
      )}
    </FlexCenter>
  );
}

export default ResourceLoadingError;
