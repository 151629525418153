export function FieldError({ children }) {
  return <span className="inline-block my-1 text-red-500 text-sm">{children}</span>;
}
export function FieldLabel({ children, htmlFor }) {
  return (
    <label htmlFor={htmlFor} className="mb-2 text-sm font-bold">
      {children}
    </label>
  );
}
