export function SectionWrapper({ children, className, variant }) {
  if (variant === "moderate") {
    className += " px-12 md:px-24 lg:px-32";
  }
  return (
    <section className={`section-wrapper | w-11/12 md:w-10/12 lg:w-9/12 mx-auto ${className}`}>
      {children}
    </section>
  );
}

export function SectionHeader({ children, className }) {
  return <header className="font-semibold text-2xl md:text-3xl mb-2 md:mb-4">{children}</header>;
}
