import React from "react";
import { BsGeoAlt } from "react-icons/bs";
import PageHero from "../../components/PageHero";
import { SectionWrapper } from "../../components/ui/wrapper";

function WorshipCenter({ worshipCentre }) {
   const { name, address, contact } = worshipCentre;
   return (
      <div className="worship-centre | w-10/12 md:w-5/12 lg:w-3/12 mb-2 mx-auto ">
         <div className="text-xl md:text-2xl mb-2 font-semibold text-[#004e76]">{name}</div>
         <div className="gray-text georgia-font italic text-base">
            <p>{address}</p>
            {!Boolean(contact) ? null : (
               <p>
                  <span className="font-semibold">Phone: </span> {contact}
               </p>
            )}
         </div>
      </div>
   );
}

const worshipCentres = [
   {
      name: "Maryland Centre",
      address: "Plot 374, Jadesola House, Ikorodu Road, Behind Maryland BRT Bus stop, Maryland, Lagos.",
      contact: "+234 810 0565 318, +234 703 483 4338",
   },
   {
      name: "Lekki Centre",
      address: "2nd Floor, Princess & Angel Mall, Lekki-Epe Express Way, Ojo Lagos Business School, Ajah.",
      contact: "+234 (0) 802 876 5551",
   },
   {
      name: "Gbagada Centre",
      address: "Oak Spring Hotel and Luxury Suite, 5, Sunday Ogunyade Street, Gbagada Phase 2, Lagos.",
      contact: "+234 (0) 808 198 5311",
   },
   {
      name: "Benin Centre",
      address: "6, Reuben Agho Avenue, Off Gapiona Junction, GRA, Benin City.",
      contact: "",
   },
];

function ConnectPage() {
   return (
      <>
         <PageHero heroBg="">Connect</PageHero>
         <SectionWrapper className="mb-12 text-center">
            <header className="section__header flex items-center justify-center">
               <span className=" text-[#00b3ff] me-2">
                  <BsGeoAlt />
               </span>{" "}
               Worship Centres
            </header>
            <div className="worship-centres | flex flex-wrap gap-4">
               {worshipCentres.map((centre, index) => (
                  <WorshipCenter key={index} worshipCentre={centre} />
               ))}
            </div>
         </SectionWrapper>
      </>
   );
}

export default ConnectPage;
