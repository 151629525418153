export default function Button({
  children,
  type = "button",
  className,
  icon = "",
  disabled = false,
  busy = false,
  iconOnly = false,
  onClick = () => {},
  ...rest
}) {
  return (
    <button
      className={`${iconOnly ? "" : "px-3 btn"} ${className ? className : ""}`}
      type={type}
      onClick={(e) => type.toLowerCase() !== "submit" && !disabled && onClick(e)}
      disabled={disabled || busy}
      {...rest}>
      <small className="flex items-center justify-center">
        {busy ? (
          "Please wait..."
        ) : (
          <>
            {icon && <i className={`${icon} me-1`}></i>} {children}
          </>
        )}
      </small>
    </button>
  );
}

export function ButtonV1({ children, variant = "", className, disabled, ...rest }) {
  switch (variant) {
    case "primary":
      className =
        "text-white border-primary-400 bg-primary-400 hover:border-primary-500 hover:bg-primary-500 active:border-primary-600 active:bg-primary-600 focus:bg-primary-500 " +
        className;
      break;
    default:
    // className = className;
  }
  return (
    <button
      className={`inline-block text-center align-middle cursor-pointer select-none border border-transparent px-3 py-[0.45rem] text-base rounded focus:shadow-formControl focus:outline-0 ${className}`}
      disabled={disabled}
      {...rest}>
      {children}
    </button>
  );
}
