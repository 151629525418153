import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import ResourceLoadingError from "../../components/ResourceLoadingError";
import LoadingComponent from "../../components/loading/LoadingComponent";
import Pagination, { PaginationButton } from "../../components/pagination/Pagination";
import "./index.css";

const { sermonsEndpoint, albumArtsPath } =
   process.env.NODE_ENV === "development"
      ? {
           sermonsEndpoint: "http://localhost:3001/v1/audios",
           albumArtsPath: "https://assets.theharvestword.org/images/album-arts/",
        }
      : {
           sermonsEndpoint: "https://api.theharvestword.org/v1/audios",
           albumArtsPath: "https://assets.theharvestword.org/images/album-arts/",
        };

function Sermons() {
   const [searchQuery, setSearchQuery] = useState("");

   return (
      <>
         <PageHero heroBg="sermons_bg.jpg">Sermons</PageHero>
         <section className="w-[90%] md:w-[80%] lg:w-[70%] mx-auto mb-12">
            <div className="search | mb-4">
               <form onSubmit={(e) => e.preventDefault()}>
                  <input
                     type="search"
                     className="block w-full border border-[#ced4da] appearance-none rounded focus:border-[#86b7fe] outline-none p-4 italic"
                     name="query"
                     value={searchQuery}
                     onChange={(e) => {
                        setSearchQuery(e.target.value);
                     }}
                     placeholder="Search messages"
                  />
               </form>
            </div>
            <SermonsList searchQuery={searchQuery} />
         </section>
      </>
   );
}

export default Sermons;

function useFetchSermons(searchQuery, page) {
   const [sermons, setSermons] = useState([]);
   const [totalPages, setTotalPages] = useState(1);
   const [error, setError] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const delay = searchQuery ? 2000 : 0;

      const timeoutId = setTimeout(() => {
         async function getPaginatedSermons() {
            setIsLoading(true);
            setError("");
            let apiUrl = `${sermonsEndpoint}?page=${page}&rows=20&search=${searchQuery}`;
            try {
               const result = await (await fetch(apiUrl)).json();
               setSermons(result.data);
               setTotalPages(result.totalPages);
            } catch (error) {
               setError("Oops! Something went wrong, please try again later");
            } finally {
               setIsLoading(false);
            }
         }
         getPaginatedSermons();
      }, delay);

      return () => {
         clearInterval(timeoutId);
      };
   }, [page, searchQuery]);

   return { sermons, totalPages, error, isLoading };
}

function Sermon({ sermon }) {
   const albumArt = "album_art_default.png";

   return (
      <div className="sermon-card | border leading-5 rounded-lg text-[#6c757d]">
         <img className="mb-4" src={`${encodeURI(albumArtsPath + albumArt)}`} alt={sermon.title} />
         <span className="sermon-card__b_line | block mx-auto mb-4"></span>
         <div className="sermon-card-details | flex flex-col items-center text-base text-center text-capitalize mb-6 mx-auto">
            <p className="sermon-card-details__title | font-bold text-break mb-4">{sermon.title}</p>
            <p className="text-sm mb-2">
               <i className="bi bi-person"></i> {sermon.preacher}
            </p>
            <p className="text-sm">
               <i className="bi bi-calendar"></i> {formatDate(sermon.releaseDate)}
            </p>
            <a href={`https://assets.theharvestword.org/sermons/${sermon.title}.mp3`}>
               <PaginationButton className="download-btn" icon="bi bi-download"></PaginationButton>
            </a>
         </div>
      </div>
   );
}

function formatDate(dateString) {
   let t = new Date(dateString);
   return (
      ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][t.getMonth()] + " " + t.getDate() + ", " + t.getFullYear()
   );
}

function SermonsList({ searchQuery }) {
   const [page, setPage] = useState(1);

   const { sermons, totalPages, error, isLoading } = useFetchSermons(searchQuery, page);

   if (isLoading) return <LoadingComponent />;
   if (error) return <ResourceLoadingError />;

   return (
      <>
         <div className="sermon-cards-tray | grid gap-6" id="sermon-cards-tray">
            {sermons.length ? sermons.map((sermon, index) => <Sermon key={index} sermon={sermon} />) : null}
         </div>
         {sermons.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : null}
      </>
   );
}
