import FlexCenter from "../FlexCenter";
import "./index.scss";

function LoadingComponent() {
  return (
    <FlexCenter className="loading |">
      <span></span>
      <span></span>
      <span></span>
    </FlexCenter>
  );
}

export default LoadingComponent;
